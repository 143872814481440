<template>
  <div class="invoice-form">
    <slot name="attach-invoice">
      <attachment-scan-input
        attachment-label="Invoice"
        :attachment-list-target="attachmentListTarget"
        :attachments="attachments"
        :items="items"
        :loading="loading"
        parse-vendor
        :scan-items-sku="user?.company?.scan_items_sku"
        :units="units"
        @preview="$emit('preview', $event)"
        @update:attachments="$emit('update:attachments', $event)"
        @update:invoice-number="checkInvoiceNumberAndEmit"
        @update:issue-date="checkIssueDateAndEmit"
        @update:items="$emit('update:items', $event)"
        @update:loading="$emit('update:loading', $event)"
        @update:order="linkOrder"
        @update:po-number="linkPO"
        @update:vendor="maybeSetVendor"
      />
    </slot>

    <link-po-section
      :order="order"
      :readonly="readonlyOrder"
      @update:dialog-open="openDialog"
      @update:order="onOrderChange"
      @update:project="$emit('update:project', $event)"
      @update:supplier="$emit('update:supplier', $event)"
    />

    <invoice-info-section
      ref="invoiceInfoRef"
      :due-date="dueDate"
      :due-date-required="isRyvit && isIntegrated"
      :full-width="fullWidth"
      :with-owner="!order?.owner"
      :invoice-id="invoiceId"
      :invoice-number="invoiceNumber"
      :issue-date="issueDate"
      :maxlength="invoiceNumberMaxLen"
      :owner="owner"
      :supplier="supplier"
      @update:due-date="$emit('update:due-date', $event)"
      @update:invoice-number="$emit('update:invoice-number', $event)"
      @update:issue-date="$emit('update:issue-date', $event)"
      @update:owner="$emit('update:owner', $event)"
    />

    <order-supplier
      ref="orderSupplierRef"
      :country="project?.country"
      :disabled="!!order"
      no-contact
      :supplier="supplier"
      @update:supplier="$emit('update:supplier', $event)"
    >
      <template v-slot:prepend>
        <v-row no-gutters>
          <v-col cols="12" sm="8" lg="6">
            <qtm-input-label class="mb-4" label="Project" required>
              <jobsite-select
                :disabled="!!order"
                :error-messages="project ? undefined : errors.project"
                hide-details="auto"
                :model-value="project"
                :user="user"
                @update:model-value="$emit('update:project', $event)"
              />
            </qtm-input-label>
          </v-col>
        </v-row>
        <v-row v-if="searchVendorId === supplier?.id">
          <v-col cols="6">
            <v-alert class="mb-4" color="mid-grey" type="info" variant="outlined">
              Supplier automatically set from scanned file, please verify it is correct.
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </order-supplier>

    <order-cart
      ref="orderCartRef"
      :items="items"
      :cost-codes="costCodes"
      :force-cost-code-select="isIntegrated"
      rearrangeable
      require-cost-codes
      :taxes="taxes"
      title="Invoice Items"
      :units="units"
      :with-cost-codes="!order"
      with-prices
      @update:items="$emit('update:items', $event)"
    >
      <template v-slot:items:prepend-left>
        <div ref="attachmentListTarget" />
      </template>
      <div class="mt-6 text-right">
        <order-total-price-estimate :skus="items" />
      </div>
    </order-cart>

    <qtm-content-block title="Additional Information">
      <qtm-input-label label="Comment">
        <qtm-textarea
          auto-grow
          hide-details="auto"
          :model-value="comment"
          rows="1"
          @update:model-value="$emit('update:comment', $event)"
        />
      </qtm-input-label>
    </qtm-content-block>

    <project-authority :project="project" />
  </div>
</template>

<script setup lang="ts">
import { required } from '@vuelidate/validators'
import { DateTime } from '@quotetome/materials-api'
import type {
  Jobsite,
  Order,
  PurchaseOrder,
  User,
  Vendor,
} from '@quotetome/materials-api'
import AttachmentScanInput from '@/components/attachments/attachment-scan-input.vue'
import InvoiceInfoSection from '@/components/invoices/invoice-info-section.vue'
import JobsiteSelect from '@/components/jobsites/jobsite-select.vue'
import LinkPoSection from '@/components/invoices/link-po-section.vue'
import OrderCart from '@/components/orders/order-cart.vue'
import OrderSupplier from '@/components/orders/order-supplier.vue'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate.vue'
import ProjectAuthority from '@/components/jobsites/project-authority.vue'
import useCostCodes from '@/composables/cost-codes'
import useDocumentSearch from '@/composables/document-search'
import useTaxes from '@/composables/taxes'
import useUnits from '@/composables/units'
import useValidation from '@/composables/validation'

export interface Props {
  attachments: any[]
  comment?: string
  dueDate?: DateTime | null
  fullWidth: boolean
  invoiceId?: number
  invoiceNumber?: string
  issueDate?: DateTime | null
  items: any[]
  loading?: boolean
  order: Order | null
  owner?: User | null
  project?: Jobsite | null
  readonlyOrder?: boolean
  supplier: Vendor | null
}

const props = defineProps<Props>()
const emit = defineEmits([
  'preview',
  'update:attachments',
  'update:comment',
  'update:due-date',
  'update:invoice-number',
  'update:issue-date',
  'update:items',
  'update:loading',
  'update:order',
  'update:owner',
  'update:project',
  'update:supplier',
])

const attachmentListTarget = ref<HTMLElement>()

const { errors, isValid: formIsValid } = useValidation({
  rules: { project: { required } },
  state: toRef(props),
})

const { search: searchOrders, results: searchedOrders } = useDocumentSearch('order', { limit: 1 })
const authStore = useAuthStore()
const isStaff = authStore.isStaff

const dialogOpen = ref(false)

const { costCodes, fetchCostCodes } = useCostCodes(props.project?.id)
const { fetchTaxes, taxes } = useTaxes(props.project?.company as number)
const { fetchUnits, units } = useUnits(props.project?.company as number)

watch(() => props.project, (project) => {
  if (project) {
    fetchCostCodes(project.id as number)
    fetchTaxes(project.company as number)
    fetchUnits(project.company as number)
  }

  props.items.forEach(item => {
    item.cost_code = ''
  })
})

const accountingIntegration = ref()
const user = computed(() => (props.owner ? props.owner : authStore.user))
const isRyvit = computed(() => accountingIntegration.value?.type === 'ryvit')
const isIntegrated = computed(() => !!props.project?.accounting_id && accountingIntegration.value?.sync_invoices)
const invoiceNumberMaxLen = computed(() => {
  if (isRyvit.value && isIntegrated.value) {
    return 15
  }

  return undefined
})

const invoiceInfoRef = ref<InstanceType<typeof InvoiceInfoSection>>()
const orderSupplierRef = ref<InstanceType<typeof OrderSupplier>>()
const orderCartRef = ref<InstanceType<typeof OrderCart>>()
const isValid = () => {
  const components: InstanceType<any>[] = [invoiceInfoRef.value]

  if (!props.order) {
    components.push(orderSupplierRef.value)
  }

  components.push(orderCartRef.value)

  const componentValidations: boolean[] = []
  let hasScrolled = false

  components.filter(c => c).forEach(c => {
    const componentValid = c!.isValid()

    componentValidations.push(componentValid)

    if (!hasScrolled && !componentValid) {
      c?.$el.scrollIntoView({ behavior: 'smooth' })
      hasScrolled = true
    }
  })

  const formValid = formIsValid()

  if (!formValid && !hasScrolled) {
    orderSupplierRef.value?.$el.scrollIntoView({ behavior: 'smooth' })
  }

  return componentValidations.every(Boolean)
}

const { $api, $error, $tracker } = useNuxtApp()
const supplierFromPo = (po: PurchaseOrder) => {
  const SUPPLIER_FIELDS = ['address', 'city', 'name', 'postal_code', 'province']
  const supplier: any = { id: po.supplier }

  SUPPLIER_FIELDS.forEach((field) => {
    supplier[field] = po[`supplier_${field}` as any]
  })

  emit('update:supplier', supplier)
}
const linkOrder = (order: Order) => {
  emit('update:project', order.jobsite)
  if (isStaff) {
    emit('update:owner', order.owner)
  }

  const po = order.pos[0]

  if (po) {
    emit('update:order', order)
    supplierFromPo(po)
  }
}
const linkPO = async (poNumber: string) => {
  const abort = () => dialogOpen.value || props.order || props.project

  if (abort()) {
    return
  }

  try {
    await searchOrders(poNumber)

    if (!searchedOrders.value.length) {
      return
    }

    const order = await $api.v1.rfqs.get((searchedOrders.value[0] as Order).id as number)

    if (abort()) {
      return
    }

    const cleanIncomingPoNumber = poNumber?.replace(/[^A-Z0-9]/gi, '')?.toLowerCase() ?? ''
    const cleanFoundPoNumber = order?.pos[0]?.po_number?.replace(/[^A-Z0-9]/gi, '')?.toLowerCase() ?? ''

    if (cleanIncomingPoNumber !== cleanFoundPoNumber || cleanIncomingPoNumber === '' || cleanFoundPoNumber === '') {
      return
    }

    emit('update:order', order)
    emit('update:project', order.jobsite)
    if (isStaff) {
      emit('update:owner', order.owner)
    }

    $tracker.track('Invoice - PO Automatched', {
      company_id: order!.jobsite!.company,
      jobsite_id: order!.jobsite!.id,
      rfq_id: order!.id,
    })

    const localPo = order.pos[0]

    if (localPo) {
      supplierFromPo(localPo)
    }
  }
  catch (error) {
    console.error(error)
  }
}

const onOrderChange = (order: Order) => {
  emit('update:order', order)
  if (isStaff) {
    emit('update:owner', order?.owner)
  }

  props.items.forEach(item => {
    item.reference_identifier = ''
  })
}

const checkInvoiceNumberAndEmit = (value: any) => {
  if (!props.invoiceNumber) {
    emit('update:invoice-number', value)
  }
}

const checkIssueDateAndEmit = (value: any) => {
  if (!props.issueDate) {
    try {
      const date = new DateTime(value)

      if (date.isValid() && date.isBefore(new DateTime().add(10, 'years'))) {
        emit('update:issue-date', date)
      }
    }
    catch (error) {
      console.error(error, value)
    }
  }
}

const fetchAccountingIntegration = async () => {
  if (!props.project) {
    return
  }

  try {
    accountingIntegration.value = await $api.v1.companies.accountingIntegration(props.project.company as number)
  }
  catch (error) {
    $error.report(error)
  }
}

const openDialog = (value: boolean) => {
  dialogOpen.value = value
}

const scrollToCart = () => {
  orderCartRef.value?.$el.scrollIntoView({ behavior: 'smooth' })
}

const searchVendorId = ref<number | null>(null)
const maybeSetVendor = (vendor: Vendor) => {
  if (!props.supplier) {
    emit('update:supplier', vendor)
    searchVendorId.value = vendor.id
  }
}

watchEffect(() => {
  if (props.project) {
    fetchAccountingIntegration()
  }
})

defineExpose({ isValid, scrollToCart })
</script>

<style lang="scss" scoped>
.invoice-form > * {
  margin-bottom: 1rem;
}
</style>
